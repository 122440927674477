import React, { useState } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import HomeWrapper from '../../components/HomeWrapper';
import Loader from 'react-loader-spinner';
import { verifyCodeApi } from '../../api/auth';
import { toast, Slide } from 'react-toastify';


export default function Verify () { 

    return (
      <HomeWrapper>
       <h3 className="text-black mb-3">
            Verify Password !
        </h3>
        <p className="text-muted mb-5">
          A Code has been sent to your Email, <br /> Click the button to Verify.
        </p>
  
      </HomeWrapper>
    )

}

