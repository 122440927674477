import React from "react";
import "./style.css";
import { ReactComponent as RifalyLogo } from "../../assets/rifalyPodcasterlogo.svg";

import { ToastContainer } from "react-toastify";
import { Carousel } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Image } from "react-bootstrap";
import slider1 from "../../assets/img/covers/RifalySlider1.svg";
import slider2 from "../../assets/img/covers/RifalySlider2.svg";
import slider3 from "../../assets/img/covers/RifalySlider3.svg";

export default function HomeWrapper({ children }) {
  return (
    <div className="d-flex align-items-center bg-auth">
      <div className="container-fluid px-0">
        <div className="row justify-content-center">
          <div className="col-12 col-md-7 col-lg-6 col-xl-6 d-none d-lg-block  p-0">
            <div className="side-bg h-100 min-vh-100">
              <Carousel
                interval={3000}
                pause={false}
                id="swiffy-animation"
                nextIcon={null}
                prevIcon={null}
              >
                <Carousel.Item>
                  <Image
                    className="d-block w-100"
                    src={slider1}
                    alt="First slide"
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    className="d-block w-100"
                    src={slider2}
                    alt="Second slide"
                    loading="lazy"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    className="d-block w-100"
                    src={slider3}
                    alt="Third slide"
                    loading="lazy"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          <div className="col-12 col-md-5 col-lg-6 col-xl-6 px-lg-6 my-5 align-self-center">
            <div className="inner-content">
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={3}
              />
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
