import {Link} from 'react-router-dom';
import DashboardWrapper from '../../../components/DashboardWrapper';
import PublisherSummary from './PublisherSummary';
import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';
import api_config from '../../../api/api_config';

import axios from 'axios';
import {DateTime} from 'luxon';
import './style.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import {Doughnut, Line} from 'react-chartjs-2';
import {useLocation, useNavigate} from 'react-router-dom';

function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ retry, setRetry ] = useState(false);
  const user = JSON.parse(localStorage.getItem('RIFALYPODCASTER'));
  const canUpload = parseInt(user.role) === 2 || parseInt(user.role) === 6;


  const [ data, setData ] = useState({summary: {platform: {}}, sales: {}});
  const [ graphData, setGraphData ] = useState({downloads: [], impressions: [], streams: []});
  const [ period, setPeriod ] = useState({end: DateTime.fromMillis(Date.now()).toISO({includeOffset: false}), start: DateTime.fromMillis(Date.now()).minus({day: 30}).startOf('day').toISO({includeOffset: false})});
  const [ start, setStart ] = useState((new Date()).toISOString());
  const [ end, setEnd ] = useState((new Date()).toISOString());
  var fx = localStorage.getItem('RIFALYCURRENCYRATES')
    ? JSON.parse(localStorage.getItem('RIFALYCURRENCYRATES'))
    : {base: 'TZS', rates: null};
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  // const getGraphData = async (id) => {
  //   const response = await axios.post(
  //     `${api_config.BILLING_URL}publisher-graph-reports`,
  //     {publisherId: id},
  //   );
  //   if (response.status == 200) {
  //     const data = response.data;
  //     setGraphData(data);
  //     return data;
  //   }
  //   return {};
  // };
  const thousandSeparator = (x) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'n/a';
  };
  var company = JSON.parse(localStorage.getItem('RIFALYCOMPANY'));
  const [ read, setRead ] = useState(0);
  const [ currency, setCurrency ] = useState('TZS');
  const [ platform, setPlatform ] = useState();
  const [ pieTitle, setPieTitle ] = useState('Plays by Platform');
  const [ pieData, setPieData ] = useState({labels: [], datasets: []});
  const [ dataLine, setDataLine ] = useState({labels: [], datasets: []});
  const [ firstTime, setFirstTime ] = useState(false)

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#BCBCBC',
          font: {
            size: 14,
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
        },
      },
    },
  };

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          color: '#BCBCBC',
          font: {
            size: 13,
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
    },
    elements: {
      bar: {
        borderRadius: 10,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          padding: {top: 20, left: 0, right: 0, bottom: 0},
        },
        grid: {
          display: false,
          borderColor: 'transparent',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Counts',
        },
        grid: {
          offset: false,
          borderDash: [ 1, 3 ],
          color: '#E0E6ED',
          borderColor: 'transparent',
          tickColor: 'transparent',
          categoryPercentage: 0.4,
        },
      },
    },
  };

  const getPublishingReports = async (data) => {
    var result = {};
    const [ salesRes, summaryResult, graphResult ] = await Promise.all([
      axios.get(`${api_config.BILLING_URL}reports/audio-sales-reports/?id=${data.id}&startDate=${data.startDate}&endDate=${data.endDate}`, {headers: {'Authorization': `Bearer ${user.token}`}}),
      axios.get(`${api_config.BILLING_URL}corporates/publishing-reports/?id=${data.id}&startDate=${data.startDate}&endDate=${data.endDate}`, {headers: {'Authorization': `Bearer ${user.token}`}}),
      axios.get(`${api_config.BILLING_URL}reports/publisher-reading-history/?id=${data.id}&startDate=${data.startDate}&endDate=${data.endDate}`, {headers: {'Authorization': `Bearer ${user.token}`}}),
    ]);

    if (summaryResult && summaryResult.status == 200) {
      result.summary = summaryResult.data.data;
    }
    if (salesRes && salesRes.status == 200) {
      result.sales = salesRes.data.data;
    }
    if (graphResult && graphResult.status == 200) {
      result.graphData = graphResult.data.data;
    }
    return result;
  };
  const prepareChartData = (data) => {
    var labels = [];
    var date = new Date();
    let currentMonth = date.getMonth();
    let currentYear = date.getFullYear();
    let today = date.getDate();
    let day = today + 1;
    for (let i = 0; i < 30; i++) {
      day--;
      if (parseInt(day) <= 0) {
        currentMonth = currentMonth - 1;
        if (currentMonth < 0) currentMonth = 11;
        day = parseInt(
          new Date(currentYear, parseInt(currentMonth) + 1, 0).getDate(),
        );
      }
      labels[ i ] = months[ currentMonth ] + ' ' + day;
    }
    labels = labels.reverse();
    var impressions = [];
    var downs = [];
    var streams = [];
    if (data.graphData) {
      labels.forEach((label, index) => {
        if (data.graphData.impressions) {
          let imp = data.graphData.impressions
            .map((i) => {
              let x = i;
              let d = new Date(i.date_created);
              x.date = `${months[ parseInt(d.getMonth()) ]} ${parseInt(d.getDate())}`;
              return x;
            })
            .find((ip) => ip.date == label);
          let val = imp ? imp.impressions : 0;
          impressions.push(val);
        }
        if (data.graphData.downloads) {
          let down = data.graphData.downloads
            .map((i) => {
              let x = i;
              let d = new Date(i.date_created);
              x.date = `${months[ parseInt(d.getMonth()) ]} ${parseInt(d.getDate())
                }`;
              return x;
            })
            .find((ip) => ip.date == label);
          let val2 = down ? down.downloads : 0;
          downs.push(val2);
        }
        if (data.graphData.streams) {
          let st = data.graphData.streams
            .map((i) => {
              let x = i;
              let d = new Date(i.date_created);
              x.date = `${months[ parseInt(d.getMonth()) ]} ${parseInt(d.getDate())
                }`;
              return x;
            })
            .find((ip) => ip.date == label);
          let val3 = st ? st.streams : 0;
          streams.push(val3);
        }
      });
    }

    var pie = {
      Android: 0, iOS: 0, Web: 0, Other: 0
    };

    if (
      data.summary && data.summary.platform
    ) {
      const pieDownloads = data.summary.platform;
      pie = {
        Android: pieDownloads.downloads
          ? pieDownloads.downloads.android
          : 0,
        iOS: pieDownloads.downloads ? pieDownloads.downloads.ios : 0,
        Web: pieDownloads.downloads ? pieDownloads.downloads.web : 0,
        Other: pieDownloads.downloads ? pieDownloads.downloads.other : 0,
      };

      if (data.summary.total.downloads < data.summary.total.streams && data.summary.total.downloads == 0) {
        setPieTitle('Plays by Platform');
        pie = {
          Android: pieDownloads.streams ? pieDownloads.streams.android : 0,
          iOS: pieDownloads.streams ? pieDownloads.streams.ios : 0,
          Web: pieDownloads.streams ? pieDownloads.streams.web : 0,
          Other: pieDownloads.streams ? pieDownloads.streams.other : 0,
        };
      }
      if (data.summary.total.downloads == 0 && data.summary.total.streams == 0) {
        setPieTitle('Impressions by Platform');
        pie = {
          Android: pieDownloads.impressions ? pieDownloads.impressions.android : 0,
          iOS: pieDownloads.impressions ? pieDownloads.impressions.ios : 0,
          Web: pieDownloads.impressions ? pieDownloads.impressions.web : 0,
          Other: pieDownloads.impressions ? pieDownloads.impressions.other : 0,
        };
      }
    }

    const pd = {
      labels: Object.keys(pie),
      datasets: [
        {
          label: 'Impressions',
          data: Object.values(pie),
          backgroundColor: [ '#2BC38C', '#2333b9', '#c66691', '#E7E7E7' ],
          radius: 120,
          weight: 1,
          cutout: 120,
          borderWidth: 1,
          hoverOffset: 7,
        },
      ],
    };

    const lineData = {
      labels,
      padding: 10,
      datasets: [
        {
          label: 'Impressions',
          data: impressions,
          borderColor: '#E7E7E7',
          backgroundColor: '#E7E7E7',
          tension: 0.4,
          barThickness: 10,
          borderRadius: 10,
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          offset: false,
        },
        {
          label: 'Downloads',
          data: downs,
          borderColor: '#2BC38C',
          backgroundColor: '#2BC38C',
          tension: 0.4,
          barThickness: 10,
          borderRadius: 10,
          barPercentage: 1.0,
          categoryPercentage: 0.4,
          offset: false,
        },
        {
          label: 'Plays',
          data: streams,
          borderColor: '#2B3422',
          backgroundColor: '#2B3422',
          tension: 0.4,
          barThickness: 10,
          borderRadius: 10,
          barPercentage: 1.0,
          categoryPercentage: 0.4,
          offset: false,
        },
      ],
    };
    setDataLine(lineData);
    setPieData(pd);
    setRead(1);
  }

  const forceReload = () => {
    window.location.reload();
    window.history.replaceState({}, document.title)
    // navigate('/dashboard', {state: {firstTime: false}})
  }

  useEffect(() => {
    if (location.state && location.state.firstTime) {
      forceReload()
    }
    if (window.performance) {
      if (performance.navigation.type == 1) {
        refresh();
      }
    }
    if (firstTime) {
      forceReload();
      setFirstTime(false)
    }
    else {
      setIsLoading(true);
      let id = JSON.parse(localStorage.getItem('RIFALYPODCASTER')).channel.id;
      const pubData = localStorage.getItem('RIFALYSUMMARY') && localStorage.getItem('RIFALYSUMMARY') != 'undefined'
        ? JSON.parse(localStorage.getItem('RIFALYSUMMARY'))
        : null;


      if (pubData != null) {
        setData(pubData);
        // setGraphData(grData);
        setIsLoading(false);
        setRetry(false);
      } else {
        const pullData = async () => {
          try {
            const result = await getPublishingReports({id: id, startDate: period.start, endDate: period.end})
            setData(result);
            // setGraphData(result.graphData);
            localStorage.setItem('RIFALYSUMMARY', JSON.stringify(result));
            // localStorage.setItem('RIFALYGRAPHDATA', JSON.stringify(result.graphData));
            setIsLoading(false);
            setRetry(false);
          } catch (e) {
            setIsLoading(false);
            setRetry(true);
          }
        }
        pullData();
      }
    }

  }, [ retry ]);
  const refresh = async () => {
    setIsLoading(true);
    let id = JSON.parse(localStorage.getItem('RIFALYPODCASTER')).channel.id;
    try {
      const result = await getPublishingReports({id: id, startDate: period.start, endDate: period.end})
      setData(result);
      localStorage.setItem('RIFALYSUMMARY', JSON.stringify(result));
      prepareChartData(result);
      setIsLoading(false);
      setRetry(false);
    } catch (e) {
      setIsLoading(false);
      setRetry(true);
    }

  };
  const handleDateChange = (e) => {
    if (e.target.id === 'start') setStart((new Date(e.target.value.trim())).toISOString());
    else setEnd((new Date(e.target.value.trim())).toISOString());
  };
  async function handleSearch(startDate, endDate) {

    const annual = {
      start: DateTime.utc((new Date()).getFullYear()).startOf('year').toISO({includeOffset: false}),
      end: DateTime.utc((new Date()).getFullYear()).endOf('year').toISO({includeOffset: false})
    }
    setIsLoading(true);
    let id = JSON.parse(localStorage.getItem('RIFALYPODCASTER')).channel.id;
    try {
      const result = await getPublishingReports({id: id, startDate: start, endDate: end})
      setData(result);
      // setGraphData(result.graphData);
      localStorage.setItem('RIFALYSUMMARY', JSON.stringify(result));
      // localStorage.setItem('RIFALYGRAPHDATA', JSON.stringify(result.graphData));
      prepareChartData(result);
      setIsLoading(false);
      setRetry(false);
    } catch (e) {
      setIsLoading(false);
      setRetry(true);
    }

  }

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3'>
              <div className='row'>
                <div className='col'>
                  <div className='header-body'>
                    <h6 className='header-pretitle'>Overview</h6>

                    <h1 className='header-title'>Dashboard</h1>
                  </div>
                </div>

                <div className='col-auto'>
                  {canUpload ?
                    <Link
                      to="/publications/stories/create"
                      className='btn btn-primary lift mt-4'>
                      <span>
                        <i className='fe fe-plus'></i>
                      </span>
                      Upload Story
                    </Link> : null}
                </div>
              </div>

              <hr></hr>
            </div>

            <div className='content'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <input type="date" className="form-control" placeholder="Select Start Date" defaultValue={start} onChange={handleDateChange} id='start' />
                    </div>
                    <div className='col-md-3'>
                      <input type="date" className="form-control" placeholder="Select End Date" defaultValue={start} onChange={handleDateChange} id='end' />
                    </div>
                    <div className='col-md-3'>
                      <button className='btn btn-primary' onClick={handleSearch}>
                        <span className='fe fe-search'></span>&nbsp;Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div className='row'>
                  <div className='col-md-12 my-4 offset-4 my-4'>
                    <Loader
                      type='TailSpin'
                      color='#46E7AD'
                      height={100}
                      width={100}
                    />
                  </div>
                </div>
              ) : retry ? (
                <div className='row'>
                  <div className='col-md-4 my-4 offset-4 my-4 text-primary text-center '>
                    <span
                      style={{cursor: 'pointer'}}
                      onClick={refresh}>
                      <i className='material-icons text-primary'>cached</i>
                    </span>
                    <p>Reload</p>
                  </div>
                </div>
              ) : (
                <div className='row'>
                  <div className='col-md-12 my-4'>
                    <div className='row'>
                      {user.role == 6 ? null : (
                        <div className='col-12 col-lg-6 col-xl'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row align-items-center gx-0'>
                                <div className='col'>
                                  <h6 className='text-capitalize text-muted mb-3'>Sales</h6>
                                  <span className='h2 mb-0 mr-4'>
                                    {data.sales && data.sales.currency
                                      ? data.sales.currency.toUpperCase()
                                      : 'TZS'}
                                    {data.sales && data.sales.amount > 0
                                      ? thousandSeparator(
                                        (
                                          0.01 *
                                          parseInt(company.commission) *
                                          data.sales.amount
                                        ).toFixed(0),
                                      )
                                      : 0}
                                  </span>

                                  <span className='badge bg-success-soft mr-2 mt-n1'></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className='col-12 col-lg-6 col-xl'>
                        <div className='card'>
                          <div className='card-body'>
                            <div className='row align-items-center gx-0'>
                              <div className='col'>
                                <h6 className='text-capitalize text-muted mb-3'>
                                  Impressions
                                </h6>
                                <span className='h2 mb-0'>
                                  {data.summary.total ? thousandSeparator(data.summary.total.impressions) : 0}
                                </span>
                                <span className='badge bg-success-soft mr-2 mt-n1'>
                                  {platform}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-12 col-lg-6 col-xl'>
                        <div className='card'>
                          <div className='card-body'>
                            <div className='row align-items-center gx-0'>
                              <div className='col'>
                                <h5 className='text-capitalize text-muted mb-3'>Plays</h5>

                                <span className='h2 mb-0'>
                                  {data.summary.total ? data.summary.total.streams : 0}
                                </span>

                                <span className='badge bg-success-soft mr-2 mt-n1'></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-12 col-xl-8'>
                        <div className='card card-fill-sm'>
                          <div className='card-header'>
                            <h4 className='card-header-title'>Performance</h4>
                          </div>
                          <div className='card-body'>
                            <Line
                              options={optionsLine}
                              data={dataLine}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='col-12 col-xl-4'>
                        <div className='card card-fill-sm'>
                          <div className='card-header'>
                            <h4 className='card-header-title'>{pieTitle}</h4>
                          </div>
                          <div className='card-body relative'>
                            <Doughnut
                              data={pieData}
                              options={options}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='card'>
                          {/* <Line data={dataLine} options={optionsLine} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Home;
