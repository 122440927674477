import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import HomeWrapper from "../../components/HomeWrapper";
import Loader from "react-loader-spinner";
import { resetPasswordApi } from "../../api/auth";
import { toast, Slide } from "react-toastify";

const schema = yup
  .object({
    token: yup.string().required("Verification code is required").label("Verification code"),
    password: yup.string().required("Password is required").min(6, "Password too short").label("Password"),
    cpassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .label("Confirm Password"),
  })
  .required();

export default function NewPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const userData = {
      token: data.token,
      password: data.password,
    };

    try {
      if (data.password !== data.cpassword) {
        setError("cpassword", {
          type: "manual",
          message: "Passwords must match",
        });
      } else {
        const response = await resetPasswordApi(userData);
        let user = response.data.data;
        localStorage.setItem("RIFALYPODCASTER", JSON.stringify(user.data));
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <HomeWrapper>
      <h3 className="text-black mb-3">Forgot Password ?</h3>
      <p className="text-muted mb-5">
        Create a new password now <br />
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("token")} value={token} />
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Type New Password
          </label>
          <input
            type="password"
            className={errors.password ? "form-control is-invalid" : "form-control"}
            autoFocus
            placeholder="****"
            {...register("password")}
          />

          <p className="errors">{errors.password?.message}</p>
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Re-type Password
          </label>
          <input
            type="password"
            className={errors.cpassword ? "form-control is-invalid" : "form-control"}
            autoFocus
            placeholder="****"
            {...register("cpassword")}
          />

          <p className="errors">{errors.cpassword?.message}</p>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            {loading ? (
              <Loader type="TailSpin" color="#ffffff" height={20} width={20} />
            ) : (
              <input className="btn btn-primary w-100" type="submit" value="Reset Password" />
            )}
          </div>
        </div>
      </form>
    </HomeWrapper>
  );
}
