import React from "react";
import "./style.css";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import avatar from "./avatar.png";
import api_config from "../../api/api_config";
export default function Navbar({ children }) {
  const user = JSON.parse(localStorage.getItem("RIFALYPODCASTER"));
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const translateRole = (roleId) => {
    let roles = [
      { id: 2, role: "Admin" },
      { id: 6, role: "Uploader" },
      { id: 7, role: "Finance" },
    ];
    let role = roles.find((r) => r.id == roleId);
    return role ? role.role : roleId;
  };

  if (!user) {
    return <Navigate to="/signout" replace />;
  }

  return (
    <nav className="navbar navbar-expand-lg fixed-top" id="topnav">
      <div className="container">
        <button
          className="navbar-toggler me-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="x-auto"></div>

        <ul className="navbar-nav mx-auto d-none">
          <li>
            <NavLink to="/dashboard" className="nav-link">
              <span>Publish</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/dashboard" className="nav-link">
              <span>Resources</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/dashboard" className="nav-link">
              <span>Support</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard" className="nav-link">
              <span>Account</span>
            </NavLink>
          </li>
        </ul>

        <div className="navbar-user">
          <Dropdown className="pointer">
            <Dropdown.Toggle as={"div"}>
              <div className="nav-user-holder">
                <div className="user-title">
                  {user.fullName ? (
                    <p className="ut-name">{user.fullName}</p>
                  ) : (
                    <p className="ut-name">No name</p>
                  )}{" "}
                </div>

                <div className="user-avatar d-flex justify-content-center align-items-center">
                  <img
                    src={
                      user.avatar == "avatar.png"
                        ? avatar
                        : `${api_config.STORAGE_URL}profiles/${user.avatar}`
                    }
                    className="avatar-img rounded-circle"
                    alt="..."
                  />
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/profile">Profile</Dropdown.Item>
              <Dropdown.Item href="/settings">Settings</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
}
