import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import   storage  from "redux-persist/lib/storage"
import companies from "./company";
import company from "./companyDetails.reducer";
import readers from "./reader";
import reader from "./readerDetails.reducer";
import admins from "./admin";
import admin from "./adminDetails.reducer";
import telcos from "./telco";
import stories from "./story";
import notifications from "./notification";
import genres from "./genre";
import { transactions } from "./archive";


const persistConfig = {
  key: 'root', 
  storage, 
  whiteList: [ 
    'companies', 'company', 
    'readers', 'reader',  
    'admins', 
    'admin', 
    'stories', 
    'genres',
    'notifications', 
    'transactions']
}

const Reducers = combineReducers({
  companies,
  company,
  readers,
  reader, 
  admins,
  admin,
  telcos,
  stories,
  genres, 
  notifications,
  transactions
});

export default persistReducer(persistConfig, Reducers)

// export default Reducers;
