import { FETCH_STORIES_LOADING, 
         FETCH_STORIES_SUCCESS,FETCH_STORY_DETAILS_LOADING,FETCH_STORY_DETAILS_SUCCESS
        } from "../Actions/story-action";


const initialState = {
  stories: [],
  isLoading: false,
};
const storyInitialState={
  story:{},
  isLoading: false
}

export function storyDetailsReducer(state = storyInitialState, action) {
  switch (action.type) {
    case FETCH_STORY_DETAILS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_STORY_DETAILS_SUCCESS: {
      return {
        ...state,
        story: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
export default function storyReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STORIES_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_STORIES_SUCCESS: {
      return {
        ...state,
        stories: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

