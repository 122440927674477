import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchAdminDetailsApi } from "../../../api/user";
import DashboardWrapper from "../../../components/DashboardWrapper";
import Loader from "react-loader-spinner";
import moment from "moment";
import api_config from "../../../api/api_config";
import avatar from "./avatar.png";

function Profile() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [profiles, setProfile] = useState({});

  var profile = JSON.parse(localStorage.getItem("RIFALYPODCASTER"));
  console.log("🚀 ~ Profile ~ profile:", profile)

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }
  var id = JSON.parse(localStorage.getItem("RIFALYPODCASTER")).id;

  // useEffect(() => {
  //   if (id) {
  //     setIsLoading(true);
  //     fetchAdminDetailsApi(id)
  //       .then((res) => {
  //         let data = res.data.data || {};
  //         if (data.fullName) {
  //           data.firstName = data.fullName.split(" ")[0];
  //           data.lastName = data.fullName.split(" ")[1];
  //         }
  //         setProfile(data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // }, [id]);

  const translateRole = (role) => {
    switch (parseInt(role)) {
      case 2:
        return "Administrator";
      case 6:
        return "Uploader";
      case 7:
        return "Finance";
      default:
        return "Uploader";
    }
  };

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>

                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    Profile
                  </li>
                </ol>
              </nav>

              <div className='content card mt-5 p-3 px-5'>
                <div className='row d-flex align-items-end'>
                  <div className='col-md-10'>
                    <h6 className='header-pretitle'>Overview</h6>

                    <h1 className='header-title'>User Profile</h1>
                  </div>
                  <div className='col-md-2'>
                    <a
                      href=''
                      className='d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile/edit", {
                          replace: false,
                          state: profile,
                        });
                      }}>
                      <span className='material-icons small'>edit</span>
                      &nbsp;Edit Profile
                    </a>
                  </div>
                </div>
                <hr />
                {isLoading ? (
                  <div className='loader-holder d-flex justify-content-center align-items-center'>
                    <Loader
                      type='BallTriangle'
                      heigth='100'
                      width='100'
                      color='green'
                      ariaLabel='loading-indicator'
                    />
                  </div>
                ) : (
                  <div>
                    {profile ? (
                      <div className='row'>
                        <div className='m-4 col-md-12'>
                          <div className='px-4'>
                            <div className='avatar-wrapper'>
                              <img
                                className='avatar'
                                src={
                                  profile.avatar == "avatar.png"
                                    ? avatar
                                    : `${api_config.STORAGE_URL}profiles/${profile.avatar}`
                                }
                                alt={profile.fullName}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='m-4 px-4 pt-4 col-md-12 row'>
                          <div className='col-md-4'>
                            <label>First Name</label>
                            <p className='value'>
                              {profile.fullName
                                ? profile.fullName.split(" ")[0]
                                : "No name"}
                            </p>
                          </div>
                          <div className='col-md-4'>
                            <label>Last Name</label>
                            <p className='value'>
                              {profile.fullName
                                ? profile.fullName.split(" ")[1]
                                : "No name"}
                            </p>
                          </div>
                          <div className='col-md-4'>
                            <label>E-mail</label>
                            <p className='value'>{profile.email}</p>
                          </div>
                          <div className='col-md-4'>
                            <label>Phone Number</label>
                            <p className='value'>{profile.phone}</p>
                          </div>

                          <div className='col-md-4'>
                            <label>Channel Name</label>
                            <p className='value'>{profile.channel?.channelName}</p>
                          </div>
                          <div className='col-md-4'>
                            <label>Role</label>
                            <p className='value'>
                              {translateRole(profile.role)}
                            </p>
                          </div>
                          <div className='col-md-4'>
                            <label>Country</label>
                            <p className='value'>{profile.channel?.country}</p>
                          </div>
                          <div className='col-md-4'>
                            <label>Date Created</label>
                            <p className='value'>
                              {moment(profile.createdAt).format("DD MMM YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Profile;
