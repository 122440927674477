import React from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import Home from "../containers/domains/dashboard/Index";
import Support from "../containers/Support";
import Validation from "../containers/Validation";
import Reports from "../containers/domains/reports/";
import StreamsReport from "../containers/domains/reports/streams";
import RevenueReports from "../containers/domains/reports/revenue";
import NotFound from "../components/NotFound/Index";
import Login from "../containers/Home/Login";
import Genres from "../containers/domains/settings/genres/Genres";
import Stories from "../containers/domains/publishers/stories/Stories";
import CreateStory from "../containers/domains/publishers/stories/CreateStory";
import EditStory from "../containers/domains/publishers/stories/EditStory";
import StoryDetail from "../containers/domains/publishers/stories/StoryDetail";
// import AdminDetails from "../containers/domains/users/admins/AdminDetails";
import Forgot from "../containers/Home/Forgot";
import Verify from "../containers/Home/Verify";
import NewPassword from "../containers/Home/NewPassword";
import Settings from "../containers/domains/settings/Settings";
import Notifications from "../containers/domains/settings/Notifications";
import Profile from "../containers/domains/profile/Profile";
import ProfileEdit from "../containers/domains/profile/ProfileEdit";

const routes = () => {
  const isAuthenticated = localStorage.getItem("RIFALYPODCASTER");
  return (
    <BrowserRouter>
      <Routes>
        {/* {isAuthenticated ? (
          <> */}
        <Route
          path='/dashboard'
          element={<Home />}
        />
        <Route
          path='/settings/genres'
          element={<Genres />}
        />
        <Route
          path='/settings/account'
          element={<Settings />}
        />
        <Route
          path='/settings/notifications'
          element={<Notifications />}
        />
        <Route
          path='/settings/account'
          element={<Settings />}
        />
        <Route
          path='/settings'
          element={<Settings />}
        />
        <Route
          exact
          path='/profile/edit'
          element={<ProfileEdit />}
        />
        <Route
          exact
          path='/profile'
          element={<Profile />}
        />

        {/* <Route path="/settings/genres" element={Genres/>} /> */}
        <Route
          path='/publications/stories/show/:id'
          element={<StoryDetail />}></Route>
        <Route
          path='/publications/stories/edit/:id'
          element={<EditStory />}></Route>
        <Route
          path='/publications/stories/create'
          element={<CreateStory />}></Route>
        <Route
          path='/publications/stories'
          element={<Stories />}></Route>

        <Route
          path='/reports/revenue'
          element={<Reports />}></Route>
        <Route
          path='/reports/streams'
          element={<StreamsReport />}></Route>
        <Route
          path='/reports'
          element={<RevenueReports />}></Route>

        <Route
          path='/support'
          element={<Support />}></Route>
        <Route
          path='/validation'
          element={<Validation />}></Route>
        {/* </>
        ) : null} */}
        <Route
          path='/forgot-password'
          element={<Forgot />}></Route>
        <Route
          path='/verify-code'
          element={<Verify />}></Route>
        <Route
          path='/password-reset'
          element={<NewPassword />}></Route>
        <Route
          path='/signout'
          element={<Login />}></Route>
        <Route
          path='/'
          element={<Login />}></Route>

        <Route element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default routes;
