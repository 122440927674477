import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import EmptyData from "../../../../components/EmptyData/Index";
import StoryFilter from "./StoryFilter";
import { AnimatePresence, motion } from "framer-motion";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function StoriesTable({ columns, data, raw, onPageChange, path }) {
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("RIFALYPODCASTER"));
  let pid = JSON.parse(localStorage.getItem("RIFALYPODCASTER")).channel.id;

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance;

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly Uploaded stories ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };
  

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    []
  );

  const nextPage = () => {
    const next = currentPage + 1;
    onPageChange(next);
    setCurrentPage(next);
  };

  const previousPage = () => {
    const prev = currentPage - 1;
    onPageChange(prev);
    setCurrentPage(prev);
  };


  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col">
          <StoryFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        </div>
          <div className="col-auto">
            <Link
              to="/publications/stories/create"
              className="btn btn-primary lift"
            >
              <span>
                <i className="fe fe-plus"></i>
              </span>
              Create Story
            </Link>
          </div>
      </div>

      <div className="row mt-2 body-content">
        {data && data.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Registered Stories</h4>

                    <div>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      <AnimatePresence>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <motion.tr
                              {...row.getRowProps({
                                layoutTransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <motion.td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </motion.td>
                                );
                              })}
                            </motion.tr>
                          );
                        })}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {raw?.pageSize} of {raw?.totalItems} elements
                  </span>
                  {raw?.hasPrevious ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={previousPage}
                    >
                      <i className="fe fe-chevron-left"></i>
                    </button>
                  ) : null}
                  <span className="table-stat">Page {raw?.currentPage ? raw?.currentPage : 1}</span>
                  {raw?.hasNext ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={nextPage}
                    >
                      <i className="fe fe-chevron-right"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Stories" path="/publications/stories/create" />
        )}
      </div>
    </div>
  );
}

export default StoriesTable;




