import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Storynavs() {
  return (
    <ul className="nav nav-tabs">

      <li className="nav-item">
        <NavLink to="/publications/stories" className="nav-link">Stories</NavLink>
      </li>  

    </ul>
  )
}
