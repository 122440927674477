import React, { useMemo, useEffect, useState } from "react";
import  DashboardWrapper  from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchStories } from "../../../../redux-store/Actions/story-action";
import Storynavs from "../storynavs";
import StoriesTable from "./StoriesTable";
import { STORIESCOLUMNS } from "./StoryColumns";
import { fetchStoriesApi } from "../../../../api/publisher";

export default function Stories(){
  const columns = useMemo(() => STORIESCOLUMNS, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let id = JSON.parse(localStorage.getItem('RIFALYPODCASTER')).channel.id;
  // const storyState = useSelector((state) => state.stories);
  // const { stories } = storyState;
  const [stories, setStory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await fetchStoriesApi(id, page);
        setStory(result.data.data);
      } catch (error) {
        console.error("Error fetching story details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const handlePageChange = (newPage) => {
    if (newPage && newPage < 1) newPage = 1;
    setPage(newPage);
    dispatch(fetchStories(newPage));
  };

  function toDashboard(e) {
    e.preventDefault()
    navigate("/dashboard");
  }

  return( 
    <DashboardWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">
           
            <div className="header mt-md-2">
 
              <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toDashboard} >Dashboard</a>
                        
                      </li>
                      <li className="breadcrumb-item active">Publications</li>
                    </ol>
              </nav>

              <div className="header-body">

      
                <h6 className="header-pretitle">
                  Overview
                </h6>

                
                <h1 className="header-title">
                  Stories
                </h1>

              </div>
            </div>


            <div className="content">
              <Storynavs></Storynavs>

              <StoriesTable
                name="Stories"
                title="Uploaded Stories"
                columns={columns}
                data={stories?.data || []}
                raw={stories}
                onPageChange={handlePageChange}
              />

            </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
  )
}