import React, { useState } from 'react';
import "./style.css";
import { ReactComponent as MootoLogo } from "../../assets/img/logo.svg";
import { ReactComponent as DashIcon } from "../../assets/img/dashboard.svg";
import { ReactComponent as UserIcon } from "../../assets/img/user.svg";
import { ReactComponent as PublisherIcon } from "../../assets/img/publisher.svg";
import { ReactComponent as ReportIcon } from "../../assets/img/Chart.svg";
import { ReactComponent as AudioIcon } from "../../assets/img/audiomic.svg";
import { ReactComponent as ArticlesIcon } from "../../assets/img/articlesLogo.svg";
import { ReactComponent as BooksIcon } from "../../assets/img/booksLogo.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/Setting.svg";
import { menuItems } from './menuItems';
import SubMenus from './SubMenus';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { NavLink, Link, useLocation } from "react-router-dom";

export default function Sidebarz() {
  let pid = JSON.parse(localStorage.getItem("RIFALYPODCASTER")).companyId;

  const location = useLocation();

  const [activeMenuItem, setActiveMenuItem] = useState('dashboard'); 
  var user = JSON.parse(localStorage.getItem("RIFALYPODCASTER"));
  
  const handleMenuItemClick = (menuItemId) => {
    setActiveMenuItem(menuItemId);
  };

  return (
    <div className="sidenav"> 
      <nav
        className="navbar navbar-vertical fixed-start navbar-expand-md "
        id="sidebar"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* <a className="navbar-brand" href=""> */}
          <MootoLogo className="mt-3" />
          {/* <img src="../../assets/img/logo.svg" className="navbar-brand-img mx-auto" alt="..." /> */}
          {/* </a> */}

          <hr className="divider" />

          <div className="collapse navbar-collapse" id="sidebarCollapse">
            <form className="mt-4 mb-3 d-md-none">
              <div className="input-group input-group-rounded input-group-merge input-group-reverse">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <div className="input-group-text">
                  <span className="fe fe-search"></span>
                </div>
              </div>
            </form>

              {/*               
              <ul className="navbar-nav">
              {menuItems.map((item, index) => {
                return <SubMenus item={item} key={index} />;
              })}  
              </ul>   */}
              <Sidebar className="navbar-nav" backgroundColor="#0C2B3B">
                <Menu className="nav-item">
                   <MenuItem component={<Link to={`/dashboard`} className={`ps-menu-button ${location.pathname === '/dashboard' ? 'active' : ''}`}/>} 
                    className="nav-link" icon={<i className="fe"><DashIcon /></i>} onClick={() => setActiveMenuItem('dashboard')}> 
                    <span className="css-12w9als">Overview</span>
                  </MenuItem> 
                  <SubMenu label="Content" icon={<i className="fe"><PublisherIcon /></i>} className="nav-link">
                    {parseInt(pid) === 6 ?   null :
                    <MenuItem component={<Link to={`/publications/stories`} className={`ps-menu-button ${location.pathname === '/publications/stories' ? 'active' : ''}`} />} 
                      className="custom-menu-item" icon={<i className="fe"><AudioIcon /></i>} onClick={() => setActiveMenuItem('stories')}> 
                      <span className="css-12w9als">Audio</span>
                    </MenuItem> }
                     {/* <MenuItem component={<Link to={`/publications/books`} className={`ps-menu-button ${location.pathname === '/publications/books' ? 'active' : ''}`} />}
                      className="custom-menu-item" icon={<i className="fe"><BooksIcon /></i>} onClick={() => setActiveMenuItem('books')}>   
                      <span className="css-12w9als">Books</span>
                    </MenuItem>
                    <MenuItem component={<Link to={`/publications/articles`} className={`ps-menu-button ${location.pathname === '/publications/articles ' ? 'active' : ''}`} />}
                       className="custom-menu-item" icon={<i className="fe"><ArticlesIcon /></i>} onClick={() => setActiveMenuItem('articles')}>  
                      <span className="css-12w9als">Articles</span>
                    </MenuItem>                   */}
                  </SubMenu>
                  <MenuItem component={<Link to={`/reports`} className={`ps-menu-button ${location.pathname === '/reports' ? 'active' : ''}`} />}
                     className="nav-link" icon={<i className="fe"><ReportIcon /></i>} onClick={() => setActiveMenuItem('reports')}>  
                    <span className="css-12w9als" >Reports</span>
                  </MenuItem>   
                  <MenuItem component={<Link to={`/settings`} className={`ps-menu-button ${location.pathname === '/settings' ? 'active' : ''}`} />}
                     className="nav-link" icon={<i className="fe"><SettingsIcon /></i>} onClick={() => setActiveMenuItem('settings')}>  
                    <span className="css-12w9als" >Settings</span>
                  </MenuItem>         
                </Menu>
              </Sidebar>
          </div>
        </div>
      </nav>
   </div>
    // </Router>
  );
}
